<template>
  <v-dialog
    v-model="dialogFormularios.estatus"
    persistent
    max-width="1200"
  >
    <v-card>
      <v-card-title primary-title>
        <span class="text-subtitle-1">Formulario prospectos</span>
        <v-spacer></v-spacer>

        <v-btn
          color="orange"
          dark
          class="mr-2"
          @click="(dialog_agregar = true), getUsuarios()"
          small
          tile
        >
          <v-icon left small>mdi-plus</v-icon>
          Agregar
        </v-btn>
        
        <v-btn
          color="primary"
          dark
          class="text-capitalize"
          @click="initialize()"
          small
          tile
        >
          <v-icon left small>mdi-refresh</v-icon>
          Actualizar
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row justify="space-between">
          <v-col cols="12" md="6">
            <v-radio-group row v-model="estatus">
              <v-radio label="Aceptado" :value="1"></v-radio>
              <v-radio label="Pendiente" :value="0"></v-radio>
              <v-radio label="Rechazado" :value="2"></v-radio>
              <v-radio label="Todos" :value="4"></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="12" md="6" lg="5">
            <v-text-field
              name="name"
              label="Buscar"
              id="id"
              dense
              append-icon="mdi-magnify"
              v-model="search"
              filled
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-data-table
              :headers="headers"
              :items="filterFormulario"
              class="elevation-0"
              :search="search"
              :mobile-breakpoint="100"
              dense
            >
              <!-- Chips de colores -->
              <template v-slot:item.aceptado="{ item }">
                <v-chip color="orange" small dark v-if="item.aceptado == 0">Pendiente</v-chip>

                <v-chip color="green" small dark v-if="item.aceptado == 1">Aceptado</v-chip>

                <v-chip color="black" small dark v-if="item.aceptado == 2">Rechazado</v-chip>
              </template>

              <template v-slot:item.actions="{ item }">
                <v-btn
                  color="primary"
                  x-small
                  @click="editItem(item)"
                  class="mr-2"
                >
                  <v-icon small>mdi-magnify</v-icon>
                </v-btn>

                <v-btn
                  color="green white--text"
                  x-small
                  @click="(dialogConfirmacion = true), infoConfirmacion(item)"
                  class="mr-2"
                >
                  <v-icon small>mdi-check</v-icon>
                </v-btn>

                <v-btn
                  color="red white--text"
                  x-small
                  @click="(dialogRechazar = true), infoConfirmacion(item)"
                  class="mr-2"
                >
                  <v-icon small>mdi-close</v-icon>
                </v-btn>

              </template>

              <template v-slot:no-data>
                <v-btn color="primary" @click="initialize" small>
                  Actualizar
                </v-btn>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-btn
          color="black"
          dark
          small
          tile
          @click="dialogFormularios.estatus = false"
        >
          <v-icon small left>mdi-close</v-icon>
          Cerrar
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
      
      <!-- Dialog de carga -->
      <Alerta v-if="respuestaAlerta" :parametros="parametros" />
      <carga v-if="cargar" />
      
      <!-- Dialogo de agregar-->
      <v-dialog v-model="dialog_agregar" max-width="450px" persistent>
        <v-card>
          <v-card-title>
            <span class="text-subtitle-1">{{ formTitle }}</span>
            <v-spacer></v-spacer>
          </v-card-title>

          <v-card-text>
            <label style="color: #333333">Nombre Completo</label>
            <v-text-field
              v-model="defaultItem.nombre_completo"
              single-line
              dense
              placeholder="Ej. Juan Carlos Jimenez."
            ></v-text-field>

            <label style="color: #333333">Edad</label>
            <v-text-field
              v-model="defaultItem.edad"
              single-line
              dense
              type="number"
              placeholder="Ej. 20"
            ></v-text-field>

            <label style="color: #333333">Correo</label>
            <v-text-field
              v-model="defaultItem.correo"
              single-line
              dense
              placeholder="Ej. juang.carlos@gmail.com"
            ></v-text-field>

            <label style="color: #333333">Direccion</label>
            <v-text-field
              v-model="defaultItem.direccion"
              single-line
              dense
              placeholder="Ej. C. José Santos Chocano 111"
            ></v-text-field>

            <label style="color: #333333">Telefono</label>
            <v-text-field
              v-model="defaultItem.numero_wa"
              single-line
              dense
              placeholder="Ej. 8134576822."
            ></v-text-field>

            <label style="color: #333333">Puesto</label>
            <v-autocomplete
              :items="puestos"
              v-model="defaultItem.puesto_interes"
              outlined
              placeholder="Ej. Desarrollador."
              dense
              item-text="puesto"
              item-value="idpuesto"
            ></v-autocomplete>

            <label style="color: #333333">¿Alguien depende de usted?</label>
            <v-radio-group v-model="defaultItem.alguien_depende">
              <v-radio label="Sí" :value="1"></v-radio>
              <v-radio label="No" :value="0"></v-radio>
            </v-radio-group>

            <label style="color: #333333">¿Actualmente estudia?</label>
            <v-radio-group v-model="defaultItem.estudiando">
              <v-radio label="Sí" :value="1"></v-radio>
              <v-radio label="No" :value="0"></v-radio>
            </v-radio-group>

            <label style="color: #333333">Horario de Estudio</label>
            <v-text-field
              v-model="defaultItem.horario_estudio"
              single-line
              dense
              placeholder="Ej. 9:00 AM"
            ></v-text-field>

            <label style="color: #333333">¿Actualmente trabaja?</label>
            <v-radio-group v-model="defaultItem.trabajando">
              <v-radio label="Sí" :value="1"></v-radio>
              <v-radio label="No" :value="0"></v-radio>
            </v-radio-group>

            <label style="color: #333333">Horario de Trabajo</label>
            <v-text-field
              v-model="defaultItem.horario_trabajo"
              single-line
              dense
              placeholder="Ej. 9:00 AM"
            ></v-text-field>

            <label style="color: #333333">Medio vacante</label>
            <v-autocomplete
              :items="medios"
              v-model="defaultItem.medio_vacante"
              outlined
              placeholder="Ej. Pagina WEB."
              dense
              item-text="medio"
              item-value="idmedio_contacto"
            ></v-autocomplete>

            <label style="color: #333333">¿Domina el ingles?</label>
            <v-radio-group v-model="defaultItem.dominio_ingles">
              <v-radio label="Sí" :value="1"></v-radio>
              <v-radio label="No" :value="0"></v-radio>
            </v-radio-group>

            <label style="color: #333333">Portafolio</label>
            <v-text-field
              v-model="defaultItem.portafolio"
              single-line
              dense
              placeholder="https://gitlab.com/gitlab-com"
            ></v-text-field>

            <v-file-input
              v-model="addcvfiles"
              accept="image/png, image/jpeg, image/bmp, application/pdf"
              placeholder="Selecciona el CV"
              label="Subir CV"
              multiple
              prepend-icon="mdi-paperclip"
              @change="cargarFotosCV()"
            >
              <template v-slot:selection="{ index, text }">
                <v-chip
                  small
                  label
                  color="primary"
                  close
                  @click:close="eliminarfile(index, text)"
                >
                  {{ text }}
                </v-chip>
              </template>
            </v-file-input>

            <v-row>
              <v-col
                cols="12"
                md="11"
                v-for="(img, i) in vistaPreviasCV"
                :key="i"
              >
                <v-card
                  class="py-4 shadowCard"
                  v-if="img.extensioArchivo != 'pdf'"
                >
                  <v-img :src="img.url" contain aspect-ratio="2"></v-img>
                  <v-btn
                    color="error"
                    small
                    @click="eliminarFoto(img.url)"
                    top
                    right
                    absolute
                    fab
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-card>
                <v-card
                  class="py-4 shadowCard"
                  v-if="img.extensioArchivo == 'pdf'"
                >
                  <embed :src="img.url" type="application/pdf" />
                  <v-btn
                    color="error"
                    small
                    @click="eliminarFoto(img.url)"
                    top
                    right
                    absolute
                    fab
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="close"> Cancelar </v-btn>
            <v-btn color="blue darken-1" class="elevation-6" dark @click="save">
              Guardar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Dialogo de editar-->
      <v-dialog v-model="dialog_editar" max-width="450px">
        <v-card>
          <v-card-title>
            <span class="text-subtitle-1">{{ formTitle2 }}</span>
            <v-spacer></v-spacer>
            <span class="text-subtitle-1">
              <strong>ID: {{ editedItem.idformulario }}</strong>
            </span>
          </v-card-title>

          <v-card-text>
            <label class="black--text font-weight-semibold"><b>Nombre Completo</b></label>
            <v-text-field
              v-model="editedItem.nombre_completo"
              single-line
              dense
              placeholder="Ej. Juan Carlos Jimenez."
              readonly
              class="mb-4"
            ></v-text-field>

            <label class="black--text font-weight-semibold"><b>Edad</b></label>
            <v-text-field
              v-model="editedItem.edad"
              single-line
              dense
              placeholder="Ej. 20 años."
              readonly
              class="mb-4"
            ></v-text-field>

            <label class="black--text font-weight-semibold"><b>Correo</b></label>
            <v-text-field
              v-model="editedItem.correo"
              single-line
              dense
              placeholder="Ej. juang.carlos@gmail.com"
              readonly
              class="mb-4"
            ></v-text-field>

            <label class="black--text font-weight-semibold"><b>Direccion</b></label>
            <v-text-field
              v-model="editedItem.direccion"
              single-line
              dense
              placeholder="Ej. C. José Santos Chocano 111"
              readonly
              class="mb-4"
            ></v-text-field>

            <label class="black--text font-weight-semibold"><b>Telefono</b></label>
            <v-text-field
              v-model="editedItem.numero_wa"
              single-line
              dense
              placeholder="Ej. 8134576822."
              readonly
              class="mb-4"
            ></v-text-field>

            <label class="black--text font-weight-semibold"><b>Puesto</b></label>
            <v-autocomplete
              :items="puestos"
              v-model="editedItem.puesto_interes"
              placeholder="Ej. Desarrollador."
              dense
              item-text="puesto"
              item-value="idpuesto"
              readonly
              class="mb-4"
            ></v-autocomplete>

            <label class="black--text font-weight-semibold"><b>¿Alguien depende de usted?</b></label>
            <v-radio-group v-model="editedItem.alguien_depende" readonly>
              <v-radio label="Sí" :value="1"></v-radio>
              <v-radio label="No" :value="0"></v-radio>
            </v-radio-group>

            <label class="black--text font-weight-semibold"><b>¿Actualmente estudia?</b></label>
            <v-radio-group v-model="editedItem.estudiando" readonly>
              <v-radio label="Sí" :value="1"></v-radio>
              <v-radio label="No" :value="0"></v-radio>
            </v-radio-group>

            <label class="black--text font-weight-semibold"><b>Horario de Estudio</b></label>
            <v-text-field
              v-model="editedItem.horario_estudio"
              single-line
              dense
              readonly
              placeholder="Ej. 9:00 AM"
              class="mb-4"
            ></v-text-field>

            <label class="black--text font-weight-semibold"><b>¿Actualmente trabaja?</b></label>
            <v-radio-group v-model="editedItem.trabajando" readonly>
              <v-radio label="Sí" :value="1"></v-radio>
              <v-radio label="No" :value="0"></v-radio>
            </v-radio-group>

            <label class="black--text font-weight-semibold"><b>Horario de Trabajo</b></label>
            <v-text-field
              v-model="editedItem.horario_trabajo"
              single-line
              dense
              placeholder="Ej. 9:00 AM"
              readonly
              class="mb-4"
            ></v-text-field>

            <label class="black--text font-weight-semibold"><b>Medio vacante</b></label>
            <v-autocomplete
              :items="medios"
              v-model="editedItem.medio_vacante"
              placeholder="Ej. Pagina WEB."
              dense
              item-text="medio"
              item-value="idmedio_contacto"
              readonly
              class="mb-4"
            ></v-autocomplete>

            <label class="black--text font-weight-semibold"><b>¿Domina el ingles?</b></label>
            <v-radio-group v-model="editedItem.dominio_ingles" readonly>
              <v-radio label="Sí" :value="1"></v-radio>
              <v-radio label="No" :value="0"></v-radio>
            </v-radio-group>

            <label class="black--text font-weight-semibold"><b>Portafolio</b></label>
            <v-text-field
              v-model="editedItem.portafolio"
              single-line
              dense
              readonly
              placeholder="https://gitlab.com/gitlab-com"
            ></v-text-field>

            <v-row>
              <v-col v-if="editedItem.cv != null && editedItem.cv != ''">
                <v-card class="elevation-0">
                  <v-card-text class="py-2" style="color: black">
                    Curriculum
                  </v-card-text>
                  <embed
                    :src="url_servidor + editedItem.cv"
                    type="application/pdf"
                  />
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-btn 
              color="black" 
              dark
              tile
              small
              @click="close"
            > 
              <v-icon left small>mdi-close</v-icon>
              Cerrar 
            </v-btn>

            <v-spacer></v-spacer>
            <v-btn
              color="red"
              dark
              small
              tile
              @click="(dialogRechazar = true), infoConfirmacion(editedItem)"
            >
              <v-icon small left>mdi-close</v-icon>
              Rechazar
            </v-btn>

            <v-btn
              color="green"
              dark
              small
              tile
              @click="(dialogConfirmacion = true), infoConfirmacion(editedItem)"
            >
              <v-icon small left>mdi-check</v-icon>
              Aceptar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogConfirmacion" max-width="600">
        <v-card>
          <v-card-text>
            <v-row>
              <v-col cols="6">
                <v-card class="elevation-0" height="100%" style="display: table">
                  <div
                    class="text-h6"
                    style="display: table-cell; vertical-align: middle"
                  >
                    <strong>¿Estás seguro de que deseas aceptar este prospecto?</strong>
                    <v-card-actions>
                      <!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
                      <v-btn
                        color="secondary"
                        text
                        large
                        class="mr-2"
                        @click="dialogConfirmacion = false"
                        >No, Cancelar</v-btn
                      >
                      <!-- Guardar la información  -->
                      <v-btn
                        color="blue lighten-1"
                        dark
                        class="elevation-6"
                        large
                        @click="update()"
                        >Sí, Aceptar</v-btn
                      >
                    </v-card-actions>
                  </div>
                </v-card>
              </v-col>
              <v-col cols="6">
                <v-img src="@/assets/contrato.jpg" class="mt-4"></v-img>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>

      <!-- Dialogo para eliminar -->
      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-text>
            <v-row justify="space-around">
              <v-col cols="6">
                <v-card class="elevation-0" height="100%" style="display: table">
                  <div
                    class="text-subtitle-1"
                    style="display: table-cell; vertical-align: middle"
                  >
                    <strong>Eliminar</strong> ¿Estás seguro que deseas eliminar el
                    ciclo?
                    <v-card-actions class="pt-6">
                      <!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
                      <v-btn
                        color="error"
                        text
                        class="mr-2"
                        @click="closeDelete()"
                        >No, cancelar</v-btn
                      >
                      <!-- Guardar la información  -->
                      <v-btn
                        color="#5C5C5C"
                        dark
                        class="elevation-6"
                        @click="deleteItemConfirm()"
                        >Si</v-btn
                      >
                    </v-card-actions>
                  </div>
                </v-card>
              </v-col>
              <v-col cols="5">
                <v-img src="@/assets/borrar.png"></v-img>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogRechazar" persistent max-width="800">
        <v-card>

          <v-card-title primary-title>
            Prospecto Rechazado
          </v-card-title>

          <v-card-text>
            <v-textarea
              v-model="mensajeRechazo"
              outlined
              dense
              label="Escriba el motivo del rechazo..."
              :rows="3"
              auto-grow
              hide-details
            >
            </v-textarea>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="secondary"
              dark
              class="mr-2"
              @click="dialogRechazar = false"
              small
              tile
            >
              <v-icon left small>mdi-cancel</v-icon>
              Cancelar
            </v-btn>
            <v-btn
              color="primary"
              dark
              class="mr-2"
              @click="update()"
              small
              tile
            >
              <v-icon left small>mdi-content-save</v-icon>
              Guardar
            </v-btn>
          </v-card-actions>
          <v-btn
            color="transparent elevation-0"
            x-small
            absolute
            top
            right
            fab
            class="mt-5 mr-0"
            @click="dialogRechazar = false"
            ><v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card>
      </v-dialog>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from 'axios';
import { mapGetters } from "vuex";
// Componentes
import Alerta from "@/components/alertas/Alerta.vue";
import carga from "@/components/alertas/carga.vue";

import validarErrores from "@/mixins/validarErrores";
import funcionesExcel from "@/mixins/funcionesExcel";

export default {
  mixins: [validarErrores, funcionesExcel],

  props: ['dialogFormularios'],

  components: {
    Alerta,
    carga,
  },

  data: () => ({
    // Alertas
    parametros: {
      dialogError: false,
      mensaje: "",
      color: "",
    },

    respuestaAlerta: false,
    loader: true,
    cargar: false,
    plantel: null,
    planteles: [],
    puestos: [],
    medios: [],
    perfiles: [],
    trabajadores: [],
    formulario: [],
    usua: [],
    addcvfiles: [],
    addporfiles: [],
    addcvfiles2: [],
    addporfiles2: [],
    vistaPreviasCV: [],
    vistaPreviasPOR: [],
    fotosCV: [],
    fotosPOR: [],
    valor: 0,
    img: null,
    url_servidor: "",
    mensajeRechazo: "",

    dialog_agregar: false,
    dialog_editar: false,
    dialogDelete: false,
    dialog: false,
    dialogConfirmacion: false,
    dialogRechazar: false,

    editedIndex: -1,

    editedItem: {
      idformulario: 0,
      nombre_completo: "",
      edad: "",
      correo: "",
      direccion: "",
      numero_wa: "",
      puesto: "",
      puesto_interes: 0,
      alguien_depende: 0,
      estudiando: 0,
      horario_estudio: "",
      trabajando: 0,
      horario_trabajo: "",
      medio_vacante: 0,
      dominio_ingles: 0,
      cv: "",
      formulario: "",
      portafolio: "",
    },

    defaultItem: {
      nombre_completo: "",
      edad: "",
      correo: "",
      direccion: "",
      numero_wa: "",
      puesto: "",
      puesto_interes: 0,
      alguien_depende: 0,
      estudiando: 0,
      horario_estudio: "",
      trabajando: 0,
      horario_trabajo: "",
      medio_vacante: 0,
      dominio_ingles: 0,
      portafolio: "",
    },

    cleanItem: {
     idformulario: 0,
      nombre_completo: "",
      edad: "",
      correo: "",
      direccion: "",
      numero_wa: "",
      puesto: "",
      puesto_interes: 0,
      alguien_depende: 0,
      estudiando: 0,
      horario_estudio: "",
      trabajando: 0,
      horario_trabajo: "",
      medio_vacante: 0,
      dominio_ingles: 0,
      cv: "",
      formulario: "",
      portafolio: "",
    },

    // Filtros:
    incio: null,
    fin: null,

    // tabla
    search: "",
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    horarios: [],
    headers: [
      { text: "ID"        , value: "idformulario"    },
      { text: "Nombre"    , value: "nombre_completo" },
      { text: "Edad"      , value: "edad"            },
      { text: "correo"    , value: "correo"          },
      { text: "Telefono"  , value: "numero_wa"       },
      { text: "Puesto"    , value: "puesto"          },
      { text: "Estatus"   , value: "aceptado"        },
      { text: "Acciones"  , value: "actions", sortable: false },
    ],

    perfilesEncargada:[],
    estatus: 4
  }),

  computed: {
    ...mapGetters("login", ["getdatosUsuario"]),
    formTitle() {
      return "Agregar Formulario";
    },
    formTitle2() {
      return "Editar Formulario";
    },

    filterFormulario( ){

      let data = this.formulario.filter( el => { return this.perfilesEncargada.includes( el.puesto_interes ) })

      if( this.estatus < 4 ){
        data = data.filter( el => { return el.aceptado == this.estatus })
      }

      return data
    }
  },

  watch: {
    dialog_agregar(val) {
      val || this.close();
    },
    dialog_editar(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  async created() {
    console.log( this.getdatosUsuario )
    await this.puestosEncargada()
    await this.initialize()
    await this.getPlanteles()
    await this.getPerfiles()
    await this.getTrabajadores()
    await this.getPuestos()
    await this.getMedios()

    this.url_servidor = axios.defaults.baseURL + "archivos/";
  },

  methods: {

    puestosEncargada() {
      this.cargar = true;
      this.perfilesEncargada = [];
      
      return this.$http.get("encargadas.get/" + this.getdatosUsuario.iderp ).then((response) => {

        this.perfilesEncargada = response.data.encargada.map((registro) => { return registro.idpuesto })

        this.cargar = false;
      }).catch((error) => {
        this.validarError(error);
      })
      .finally(() => {
        this.cargar = false;
      });
    },

    initialize() {
      this.cargar = true;
      this.formulario = [];
      
      return this.$http.get("formulario.get").then((response) => {

        this.formulario = response.data.formularios

        this.cargar = false;
      }).catch((error) => {
        this.validarError(error);
      })
      .finally(() => {
        this.cargar = false;
      });
    },

    async save() {
     
      //Validaciones de campos

      if (this.defaultItem.nombre_completo == "") {
        return this.validarErrorDirecto("Favor de ingresar el nombre completo");
      }

      if (this.defaultItem.edad == "") {
        return this.validarErrorDirecto("Favor de ingresar la edad");
      }

      if (this.defaultItem.correo == "") {
        return this.validarErrorDirecto("Favor de ingresar el correo");
      }

      if (this.defaultItem.direccion == "") {
        return this.validarErrorDirecto("Favor de ingresar la dirección");
      }

      if (this.defaultItem.numero_wa == "") {
        return this.validarErrorDirecto("Favor de ingresar el telefono");
      }

      let puesto = this.puestos.find(
        (el) => el.idpuesto == this.defaultItem.puesto_interes
      );

      if (!puesto) {
        return this.validarErrorDirecto("Favor de seleccionar un puesto");
      }

      let fotosCV = [];
      if (this.vistaPreviasCV.length) {
        let respuesta = await this.subirFotosServidorCV();
        fotosCV = respuesta.data;
      }
      let nombreCV = fotosCV.nombre;

      let payload = {
        nombre_completo: this.defaultItem.nombre_completo,
        edad: this.defaultItem.edad,
        correo: this.defaultItem.correo,
        direccion: this.defaultItem.direccion,
        numero_wa: this.defaultItem.numero_wa,
        puesto_interes: this.defaultItem.puesto_interes,
        alguien_depende: this.defaultItem.alguien_depende,
        estudiando: this.defaultItem.estudiando,
        horario_estudio: this.defaultItem.horario_estudio,
        trabajando: this.defaultItem.trabajando,
        horario_trabajo: this.defaultItem.horario_trabajo,
        medio_vacante: this.defaultItem.medio_vacante,
        dominio_ingles: this.defaultItem.dominio_ingles,
        cv: nombreCV,
        portafolio: this.defaultItem.portafolio,
      };

      this.$http
        .post("formulario.add", payload)
        .then((response) => {
          this.validarSuccess(response.data.message);
          this.initialize();
          this.$nextTick(() => {
            this.defaultItem = Object.assign({}, this.cleanItem);
            this.editedIndex = -1;
          });
          (this.vistaPreviasCV = []), (this.vistaPreviasPOR = []);
        })
        .catch((error) => {
          this.loading = false;
        })
        .finally(() => {
          this.loading = false;
        });
      this.close();
    },

    async update() {
      //Conseguimos la info para las fotos
      let fotosCV = [];
      // let fotosPOR = [];

      if (this.vistaPreviasCV.length) {
        let respuesta = await this.subirFotosServidorCV();
        fotosCV = respuesta.data;
      }

      //aqui esta la info del CV y el Portafolio
      let nombreCV = fotosCV.nombre;
      // let nombrePOR = fotosPOR.nombre;

      let payload = {
        id              : this.editedItem.idformulario,
        nombre_completo : this.editedItem.nombre_completo,
        edad            : this.editedItem.edad,
        correo          : this.editedItem.correo,
        direccion       : this.editedItem.direccion,
        numero_wa       : this.editedItem.numero_wa,
        puesto_interes  : this.editedItem.puesto_interes,
        alguien_depende : this.editedItem.alguien_depende,
        estudiando      : this.editedItem.estudiando,
        horario_estudio : this.editedItem.horario_estudio,
        trabajando      : this.editedItem.trabajando,
        horario_trabajo : this.editedItem.horario_trabajo,
        medio_vacante   : this.editedItem.medio_vacante,
        dominio_ingles  : this.editedItem.dominio_ingles,
        cv              : this.editedItem.cv, //La informacion de cv y portafolio esta oculta
        portafolio      : this.editedItem.portafolio,
        motivo_rechazo  : null,
        aceptado        : 0,
        deleted         : 0,
        etapa           : 0 
      };

      //En caso de que se quiera actualizar el CV o el portafolio actualizamo el payload antes de enviarlo
      if (nombreCV) {
        payload.cv = nombreCV;
      }

      if (this.dialogConfirmacion == true) {
        payload.aceptado = 1;
      }

      if (this.dialogRechazar == true) {
        payload.aceptado = 2;
        payload.motivo_rechazo = this.mensajeRechazo;
      }

      this.cargar = true;
      this.$http
        .put("formulario.update/" + payload.id, payload)
        .then((response) => {
          this.validarSuccess(response.data.message);
          this.initialize();
          this.addcvfiles = [];
          this.addporfiles = [];
          this.vistaPreviasCV = "";
          this.vistaPreviasPOR = "";
          this.dialogConfirmacion = false;
          this.dialogRechazar = false;
          this.mensajeRechazo = "";
        })
        .catch((error) => {
          this.validarError(error);
        })
        .finally(() => {
          this.cargar = false;
        });

      this.close();
    },

    getPlanteles() {
      this.cargar = true;
      this.planteles = [];
      return this.$http.get("planteles.activos")
        .then((response) => {
          this.planteles = response.data;
          this.cargar = false;
        })
        .catch((error) => {
          this.validarError(error);
        })
        .finally(() => {
          this.cargar = false;
        });
    },

    getPerfiles() {
      this.cargar = true;
      this.perfiles = [];
      return this.$http
        .get("usuarios.erp.get.perfilesERP")
        .then((response) => {
          this.perfiles = response.data;
          this.cargar = false;
        })
        .catch((error) => {
          this.validarError(error);
        })
        .finally(() => {
          this.cargar = false;
        });
    },

    getTrabajadores() {
      this.cargar = true;
      this.trabajadores = [];
      return this.$http
        .get("usuarios.erp.get.trabajadoresERP")
        .then((response) => {
          this.trabajadores = response.data;
          this.cargar = false;
        })
        .catch((error) => {
          this.validarError(error);
        })
        .finally(() => {
          this.cargar = false;
        });
    },

    getUsuarios() {
      this.cargar = true;
      this.usua = [];
      return this.$http
        .get("usuarios.erp.get.IdUsuarioERP")
        .then((response) => {
          this.usua = response.data;
          this.valor = this.usua[0].id_agregar;
          this.cargar = false;
        })
        .catch((error) => {
          this.validarError(error);
        })
        .finally(() => {
          this.cargar = false;
        });
    },

    editItem(item) {
      this.editedIndex = this.horarios.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog_editar = true;
    },

    infoConfirmacion(item) {
      this.editedIndex = this.horarios.indexOf(item);
      this.editedItem = Object.assign({}, item);
    },

    deleteItem(item) {
      this.editedIndex = this.horarios.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.cargar = true;
      // Lo mandapos por el EP
      this.editedItem["id_usuario"] = this.getdatosUsuario.iderp;
      this.$http
        .put("horarios.eliminar/" + this.editedItem.id_horario, this.editedItem)
        .then((response) => {
          this.cargar = true;
          this.closeDelete();
          this.initialize();
        })
        .catch((error) => {
          this.validarError(error);
        })
        .finally(() => {
          this.cargar = false;
        });
    },

    close() {
      this.dialog_editar = false;
      this.dialog_agregar = false;
      this.$nextTick(() => {
        this.defaultItem = Object.assign({}, this.cleanItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDialog() {
      this.dialog = false;
    },

    //Angel
    cargarFotosCV() {
      if (this.addcvfiles.length < 1) {
        return;
      }

      this.addcvfiles.forEach((element, index) => {
        // creamos una variable tipo FormData
        let formData = new FormData();
        //se crea el objeto y se le agrega como un apendice el archivo
        formData.append("file", element);
        //mandamos a ocvertir la imagen a base64 pero mandamos el docuemnto, el formdata, el nombre
        this.getBase64CV(element, formData);
      });
    },

    cargarFotosPOR() {
      if (this.addporfiles.length < 1) {
        return;
      }

      this.addporfiles.forEach((element, index) => {
        // creamos una variable tipo FormData
        let formData = new FormData();
        //se crea el objeto y se le agrega como un apendice el archivo
        formData.append("file", element);
        //mandamos a ocvertir la imagen a base64 pero mandamos el docuemnto, el formdata, el nombre
        this.getBase64POR(element, formData);
      });
    },

    getBase64CV(file, formData) {
      var me = this;
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        const nombreSplit = file.name.split(".");
        const extensioArchivo = nombreSplit[nombreSplit.length - 1];
        me.vistaPreviasCV.push({
          url: reader.result,
          formData: formData,
          image_name: file.name,
          file,
          extensioArchivo,
        });
      };
    },

    getBase64POR(file, formData) {
      var me = this;
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        const nombreSplit = file.name.split(".");
        const extensioArchivo = nombreSplit[nombreSplit.length - 1];
        me.vistaPreviasPOR.push({
          url: reader.result,
          formData: formData,
          image_name: file.name,
          file,
          extensioArchivo,
        });
      };
    },

    eliminarFoto(value) {
      this.vistaPreviasCV.forEach((element, index) => {
        if (element.url == value) {
          this.vistaPreviasCV.splice(index, 1);
        }
      });
      this.vistaPreviasPOR.forEach((element, index) => {
        if (element.url == value) {
          this.vistaPreviasPOR.splice(index, 1);
        }
      });
    },

    eliminarfile(index, value) {
      this.addcvfiles = this.addcvfiles.filter((el) => {
        return el.name != value;
      });
      this.addporfiles = this.addporfiles.filter((el) => {
        return el.name != value;
      });
      this.addcvfiles2 = this.addcvfiles2.filter((el) => {
        return el.name != value;
      });
      this.addporfiles2 = this.addporfiles2.filter((el) => {
        return el.name != value;
      });
    },

    //Antes de subir las fotos las pasamos por aqui
    subirFotosServidorCV() {
      let formData = new FormData();

      for (const i in this.vistaPreviasCV) {
        formData.append("file", this.vistaPreviasCV[i].file);
      }

      return new Promise((resolve, reject) => {
        // obtener la extensión del archivo
        // Hacemos la petición
        this.$http
          .post(`formulario.add_archivo`, formData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            this.validarError(error);
          })
          .finally(() => {
            this.cargar = false;
          });
      });
    },

    subirFotosServidorPOR() {
      let formData = new FormData();

      for (const i in this.vistaPreviasPOR) {
        formData.append("file", this.vistaPreviasPOR[i].file);
      }

      return new Promise((resolve, reject) => {
        // obtener la extensión del archivo
        // Hacemos la petición
        this.$http
          .post(`formulario.add_archivo`, formData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            this.validarError(error);
          })
          .finally(() => {
            this.cargar = false;
          });
      });
    },

    getPuestos() {
      this.puestos = [];
      return this.$http.get("formulario.get_all_puestos").then((response) => {
        this.puestos = response.data;
      }).catch((error) => {
        this.validarError(error);
      }).finally(() => {
        this.cargar = false;
      });
    },

    getMedios() {
      this.medios = [];
      return this.$http.get("formulario.get_all_medio_vacante")
        .then((response) => {
          this.medios = response.data;
        })
        .catch((error) => {
          this.validarError(error);
        })
        .finally(() => {
          this.cargar = false;
        });
    },
  },
};
</script>


